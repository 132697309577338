import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './BookingDetails.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import React, { useState, useEffect } from 'react';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { Button, SIZE } from 'wix-ui-tpa/Button';
import { ReactComponent as CameraSVG } from 'wix-ui-tpa/dist/src/assets/icons/Camera.svg';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { BookingDetailsDataHooks } from './BookingDetailsDataHooks';
import { BookingDetailsViewModel } from '../../ViewModel/bookingDetailsViewModel/bookingDetailsViewModel';
import { Divider } from 'wix-ui-tpa/Divider';
import { Dropdown, DropdownOptionProps } from 'wix-ui-tpa/Dropdown';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { BookingPreference } from '../../../../utils/bookingPreferences/bookingPreferences';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';

export interface BookingDetailsProps {
  viewModel: BookingDetailsViewModel;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const { isMobile } = useEnvironment();
  const { onSubmit } = useCalendarActions();
  const {
    preferences: { bookingPreferences },
    ctaText,
    ctaFullWidth,
    disableCTAButton,
  } = viewModel;

  const shouldShowPreferences = () => {
    return bookingPreferences.some(
      (bookingPreference: BookingPreference) =>
        bookingPreference.options.length > 1,
    );
  };

  return (
    <div
      data-hook={BookingDetailsDataHooks.BOOKING_DETAILS_SELECTION_WRAPPER}
      className={st(classes.root, { isMobile })}
    >
      <TimeSlotDetails viewModel={viewModel} />
      {shouldShowPreferences() ? (
        <BookingPreferences viewModel={viewModel} />
      ) : null}
      <Button
        disabled={disableCTAButton}
        size={SIZE.medium}
        className={classes.bookButton}
        data-hook={BookingDetailsDataHooks.BOOK_BUTTON}
        upgrade
        fullWidth={ctaFullWidth}
        onClick={() => onSubmit()}
      >
        {ctaText}
      </Button>
    </div>
  );
};
export default BookingDetails;

const TimeSlotDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const {
    serviceName,
    dateAndTime,
    paymentDescription,
    preferences: { bookingPreferences },
    videoConferenceBadgeText,
  } = viewModel;

  return (
    <>
      <div
        data-hook={BookingDetailsDataHooks.SLOT_DETAILS}
        className={classes.serviceNameAndDate}
      >
        {videoConferenceBadgeText && (
          <Badge
            data-hook={BookingDetailsDataHooks.VIDEO_CONFERENCE_BADGE}
            priority={BADGE_PRIORITY.light}
            icon={<CameraSVG />}
            className={classes.videoConferenceBadge}
          >
            {videoConferenceBadgeText}
          </Badge>
        )}
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_NAME}
          className={classes.commonTitleStyles}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {serviceName}
        </Text>
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_DATE_AND_TIME}
          className={classes.commonTitleStyles}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {dateAndTime}
        </Text>
      </div>
      {bookingPreferences.map((bookingPreference) => {
        return bookingPreference.options.length === 1 ? (
          <Text
            data-hook={`${BookingDetailsDataHooks.TIME_SLOT_DETAILS}-${bookingPreference.key}`}
            className={classes.commonTextStyle}
            tagName={AccessibilityHtmlTags.Paragraph}
            aria-label={bookingPreference.options[0].ariaLabel}
          >
            {bookingPreference.options[0].value}
          </Text>
        ) : null;
      })}
      <Text
        data-hook={BookingDetailsDataHooks.SLOT_PLAN_TYPE}
        className={classes.commonTextStyle}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {paymentDescription}
      </Text>
    </>
  );
};

const BookingPreferences: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const {
    onBookingPreferenceOptionSelected,
    onClearBookingDetailSelectedOptions,
    onElementClicked,
  } = useCalendarActions();
  const [forceDropdownRender, setForceDropdownRender] = useState(false);
  const [isClearButtonVisible, setClearButtonVisibility] = useState(false);
  const [selectedDropDown, setSelectedDropDown] = useState<BookingPreference>();
  const { dateAndTime, preferences } = viewModel;
  const { bookingPreferences, titleText, clearText } = preferences;

  useEffect(() => {
    setClearButtonVisibility(false);
  }, [dateAndTime]);

  const clearDropdowns = () => {
    setForceDropdownRender((currentForceRender) => !currentForceRender);
    onClearBookingDetailSelectedOptions();
    setClearButtonVisibility(false);
  };

  const onDropdownChange = (
    selectedOption: DropdownOptionProps,
    bookingPreference: BookingPreference,
  ) => {
    onBookingPreferenceOptionSelected({
      key: bookingPreference.key,
      value: selectedOption.id!,
    });
    setClearButtonVisibility(true);
  };

  const onDropdownClick = (bookingPreference: BookingPreference) => {
    onElementClicked(
      WidgetComponents.BOOKING_DETAILS,
      WidgetElements.DROPDOWN,
      bookingPreference.key,
    );
    setSelectedDropDown(bookingPreference);
  };

  const getDropdownOptionsForBookingPreference = (
    bookingPreference: BookingPreference,
  ): DropdownOptionProps[] => {
    const dropdownSubtitle = {
      id: bookingPreference.placeholder,
      value: bookingPreference.placeholder,
      isSelectable: false,
      isSectionTitle: true,
    };

    return [dropdownSubtitle, ...bookingPreference.options];
  };

  return (
    <div data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_WRAPPER}>
      <Divider
        className={classes.divider}
        data-hook={BookingDetailsDataHooks.DIVIDER}
      />
      <div className={classes.bookingPreferences}>
        <p className={classes.preferencesTitle}>
          <Text
            data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_TITLE}
            className={classes.commonTitleStyles}
            tagName={AccessibilityHtmlTags.Paragraph}
          >
            {titleText}
          </Text>
        </p>
        {bookingPreferences.map((bookingPreference: BookingPreference) => {
          if (bookingPreference.options.length > 1) {
            return (
              <Dropdown
                error={!!bookingPreference.error.message}
                errorMessage={bookingPreference.error.message}
                data-hook={
                  BookingDetailsDataHooks.DROPDOWN + '-' + bookingPreference.key
                }
                className={st(classes.commonDropDownStyle, {
                  aboveAll: selectedDropDown === bookingPreference,
                })}
                aria-label={bookingPreference.placeholder}
                placeholder={bookingPreference.placeholder}
                options={getDropdownOptionsForBookingPreference(
                  bookingPreference,
                )}
                onExpandedChange={() => onDropdownClick(bookingPreference)}
                key={dateAndTime + bookingPreference.key + forceDropdownRender}
                onChange={(selectedOption) =>
                  onDropdownChange(selectedOption, bookingPreference)
                }
              />
            );
          }
          return null;
        })}
        {isClearButtonVisible ? (
          <p className={classes.clearPreferences}>
            <TextButton
              data-hook={BookingDetailsDataHooks.CLEAR_BUTTON}
              className={classes.commonTitleStyles}
              onClick={() => clearDropdowns()}
            >
              <span className={classes.textWithEllipsis}>{clearText}</span>
            </TextButton>
          </p>
        ) : null}
      </div>
    </div>
  );
};
